import React, { useContext } from 'react'

import StoreContext from '~/context/StoreContext'
import Button from './ui/Button'
import { Flex, Box } from 'reflexbox'
import Heading from './ui/Heading'
import Text from './ui/core/Text'
import { border } from 'styled-system'
import styled from '@emotion/styled'
import Link from './ui/core/Link'

const Item = styled(Flex)(border)

const Remove = styled(Link)`
  cursor: pointer;
`

const Cart = () => {
  const {
    removeLineItem,
    store: { checkout, client },
  } = useContext(StoreContext)

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  const handleRemove = id => {
    removeLineItem(client, checkout.id, id)
  }

  if (checkout.lineItems.length <= 0) {
    return (
      <Text fontSize={[3, 3, 4]} mb={4}>
        You have nothing in your cart.
      </Text>
    )
  }

  return (
    <div>
      <Text fontSize={[3, 3, 4]} mb={4}>
        Nice! Here's what you're ordering:
      </Text>
      {checkout.lineItems.map(item => (
        <Item
          key={item.id.toString()}
          borderBottom="3px solid"
          borderColor="keyline"
          py={4}
        >
          <Box width={[1 / 4, 1 / 4]}>
            <img
              style={{ maxWidth: '100%' }}
              alt={item.title}
              src={client.image.helpers.imageForSize(item.variant.image, {
                maxWidth: 300,
                maxHeight: 200,
              })}
            />
          </Box>
          <Box width={[3 / 4, 3 / 4]} pl={3}>
            <Heading level={4}>{item.title}</Heading>
            <Remove
              as="button"
              p={0}
              bg="transparent"
              color="blue"
              border="none"
              borderBottom="1px solid currentColor"
              onClick={() => handleRemove(item.id)}
            >
              Remove
            </Remove>
          </Box>
          <Box>
            <Text fontSize={[2, 3]}>${item.variant.price}</Text>
          </Box>
        </Item>
      ))}
      <Flex mt={4} justifyContent="space-between">
        <Box>
          <Heading level={4} as="h3">
            Total
          </Heading>
        </Box>
        <Box>
          <Heading level={4} as="span">
            ${checkout.totalPrice}
          </Heading>
        </Box>
      </Flex>
      <Text as="p">Taxes and shipping are calculated at checkout</Text>
      <Button
        my={4}
        onClick={handleCheckout}
        disabled={checkout.lineItems.length === 0}
      >
        Checkout
      </Button>
    </div>
  )
}

export default Cart
