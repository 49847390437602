import React from 'react'

import Cart from '~/components/Cart'
import SEO from '~/components/seo'
import Container from '../components/ui/Container'
import { Flex, Box } from 'reflexbox'
import Heading from '../components/ui/Heading'

const CartPage = () => (
  <>
    <SEO title="Cart" />

    <Container>
      <Flex my={4} justifyContent="center">
        <Box width={[1, 2 / 3, 2 / 3]}>
          <Heading mb={4} level={2} as="h2">
            Cart
          </Heading>
          <Cart />
        </Box>
      </Flex>
    </Container>
  </>
)

export default CartPage
